.fullScreenContentContainer {
  display: flex;
  justify-content: center;
}

.fullScreenContent {
  display: flex;
  justify-content: center;
  border-top: 1px solid #fff;
  height: calc(85vh - 167px);
  overflow-y: auto;
  width: 85vw;
}
