.inventoryTable {
  width: 100%;
  border: 2px solid #808080;
  border-radius: 5px;
  margin: 20px;
}

.inventoryHeader {
  font-size: 20px;
  padding-left: 10px;
  padding-top: 10px;
  background-color: #808080;
  display: flex;
  justify-content: space-between;
}

.inventoryCustomization {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-size: 18px;
  padding: 10px;
  background-color: #808080;
}

.inventorySearch {
  display: flex;
}

.inventoryFilters {
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #fff;
}

.inventoryFilters:hover {
  cursor: pointer;
  border: 1px solid #000;
  transition: 0.2;
}

.paginationContainer {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #808080;
}

.arrows {
  display: flex;
}

.paginationArrow {
  margin-left: 5px;
  margin-right: 5px;
  background-color: #fff;
  border-radius: 5px;
}

.paginationArrow:hover {
  cursor: pointer;
}
