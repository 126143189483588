.navBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #131313;
  color: #c7c7c7;
  height: 55px;
  box-shadow: 0 0px 15px 0px #00000066;
}

.achievementsIcon {
  margin-right: 5px;
}

.changeLogIcon,
.helpIcon {
  margin-right: 6px;
}

.navIcon:hover {
  transition: 0.2s;
  color: #ffffff;
}

.navIcon:hover,
.navManagement:hover {
  cursor: pointer;
}

.navManagement {
  padding: 5px 60px 5px 60px;
  border: 2px solid #c7c7c7;
  border-radius: 5px;
  font-size: 18px;
  color: #ffffff;
}

.navManagement:hover {
  transition: 0.2s;
  border: 2px solid #ffffff;
}

.navLeft {
  margin-left: 25px;
  width: 150px;
  display: flex;
  justify-content: left;
}

.navRight {
  margin-right: 25px;
  width: 150px;
  display: flex;
  justify-content: right;
}
