.fullScreenMenu {
  width: 100vw;
  position: absolute;
  z-index: 10;
  color: #fff;
  box-shadow: 0 0px 15px 0px #00000066;
}

.fullScreenNav {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  border-bottom: 1px solid #fff;
}

.cashAmount {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 35px;
  padding-top: 10px;
  height: 45px;
}

.fullScreenTabs {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: large;
  padding-bottom: 5px;
  height: 45px;
  border-bottom: 1px solid #fff;
}

.xIcon {
  padding: 5px 40px 5px 40px;
  border: 2px solid #c7c7c7;
  color: #ffffff;
  border-radius: 5px;
}

.xIcon:hover {
  cursor: pointer;
  transition: 0.2s;
  border: 2px solid #ffffff;
}
