.sellPanel {
  border-bottom: 1px solid #000;
  margin-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.errorQuantity {
  padding: 3px;
  margin: 2px;
  background-color: rgb(248, 177, 177);
  width: max-content;
  border-radius: 5px;
}