.inventoryContent {
  margin-right: 10px;
  margin-left: 10px;
}

.inventoryItem {
  border-bottom: 2px solid #000;
}

.inventoryItem,
.inventoryOptions {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.itemOption {
  margin: 5px;
}
