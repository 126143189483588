.box {
  height: 20px;
  width: 20px;
  background-color: blueviolet;
}

.sideContainer {
  width: 100%;
  height: calc(100vh - 55px);
  background-color: rgb(184, 184, 241);
}

.showScroll {
  overflow-y: scroll !important;
}

.mainContent {
  z-index: 5;
  height: calc(100vh - 55px);
  overflow-y: hidden;
  display: flex;
  justify-content: space-evenly;
}

.centerContainer {
  width: 50vw;
  min-width: 800px;
}

.spacer {
  height: 100vh;
  min-width: 17px;
  background-color: rgb(184, 184, 241);
}

.localSelection {
  display: flex;
  justify-content: center;
}

.planetHeaders {
  text-align: center;
  margin-top: 7vh;
}

.planetTitle,
.activeCityLocations {
  margin-bottom: 10px;
}

.activeCity {
  font-size: 35px;
}

.planetTitle {
  color: grey;
  font-size: 25px;
}

.activeCityLocations {
  display: flex;
  justify-content: space-between;
  width: 175px;
}

.activeCityLocation,
.cityLocation {
  font-size: 20px;
}

.activeCityLocation {
  text-decoration: underline;
}

.cityLocation:hover {
  cursor: pointer;
  text-decoration: underline;
}

.cityContent {
  display: flex;
  justify-content: center;
  text-align: center;
}

.activityContainer {
  min-width: 800px;
  border: 1px solid #000;
}
