.facilitiesTable {
  width: 100%;
  border: 2px solid #808080;
  border-radius: 5px;
  margin: 20px;
}

.warehouseHeader {
  background-color: #808080;
  padding: 10px;
  font-size: 18px;
}
