.fullScreenTab,
.fullScreenTab-active {
  font-size: 25px;
  margin-left: 10px;
  margin-right: 10px;
}

.fullScreenTab:hover {
  text-decoration: underline;
  transition: 0.4s;
  cursor: pointer;
}

.fullScreenTab-active {
  text-decoration: underline;
}